import React, { useContext } from "react";
import { ThemeContext } from "../../context";

const Title = ({title, des}) => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="flex flex-col gap-4 font-titleFont mb-14">
      <h3 className="text-sm uppercase font-light text-designColor tracking-wide">
       {title}
      </h3>
      <h1 className={`text-3xl md:text-4xl ${darkMode?'text-gray-300':'text-darkText'} font-bold capitalize`}>{des}</h1>
    </div>
  );
}

export default Title