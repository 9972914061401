import React from "react";
import { motion } from "framer-motion";

const Skills = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-3xl md:text-4xl font-bold">Design Skill</h2>
        </div>
        <div className='className="mt-14 w-full flex flex-col gap-6'>
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">Responsive Design</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">Tailwind CSS</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[100%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">Figma</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[90%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">Adobe XD.</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[60%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>

          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">User Experience</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[100%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
        </div>
      </div>

      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-3xl md:text-4xl font-bold">Development Skill</h2>
        </div>
        <div className="flex flex-col gap-6">
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">React</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-full h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">Next.js</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[100%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">HTML5 / CSS3</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[95%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>

          <div className="overflow-x-hidden">
            <p className="text-sm font-medium">Javascript / Typescript</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[95%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
          <div className="overflow-x-hidden">
            <p className="text-sm  font-medium">Node</p>
            <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
              <motion.span
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="w-[90%] h-full bg-gradient-to-r to-[#43aa8b] from-[#273043] via-[#2f9c95] rounded-md relative"
              ></motion.span>
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Skills;
