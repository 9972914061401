import React, { useContext } from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";
import { ThemeContext } from "../../context";

const ContactLeft = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div
      className={`w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg ${
        darkMode ? "shadow-shadowOne" : ""
      } flex flex-col gap-8 justify-center`}
    >
      <img
        className="w-full h-64 object-contain rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Uzman Munib</h3>
        <p className="text-lg font-normal text-gray-400">
          Full Stack Developer
        </p>
        <p className="text-base text-gray-400 tracking-wide">
          Let's discuss how I can benefit you!
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+92 3154888422</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email:{" "}
          <span className="text-lightText">
            <a href="mailto:uzmanA3@gmail.com">uzmanA3@gmail.com</a>
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base font-titleFont mb-4">Find me on:</h2>
        <div className="flex gap-4">
          <span className={darkMode ? "bannerIconDark" : "bannerIconLight"}>
            <a
              href="https://github.com/Mian-Uzman"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub />
            </a>
          </span>
          <span className={darkMode ? "bannerIconDark" : "bannerIconLight"}>
            <a
              href="https://www.linkedin.com/in/mian-uzman-munib/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactLeft;
