import React, { useContext } from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaLinkedinIn, FaReact, FaGithub } from "react-icons/fa";
import { SiTailwindcss, SiFigma, SiNextdotjs } from "react-icons/si";
import { ThemeContext } from "../../context";
const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Full Stack Developer.", "UI Designer.", "React/Next.js Developer"],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        {/* <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4> */}
        <h1
          className={`text-5xl font-bold ${
            darkMode ? "text-white" : "text-darkText"
          } `}
        >
          Hi, I'm{" "}
          <span className={"text-designColor capitalize"}>Uzman Munib</span>
        </h1>
        <h2 className={`text-2xl font-bold ${
            darkMode ? "text-white" : "text-darkText"
          } `}>
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#2f9c95"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Crafting modern, responsive, and intuitive web experiences. Elevate
          your online presence with clean code, creative design, and a passion
          for innovation. Let's bring your digital vision to life.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
            <span className={darkMode?"bannerIconDark":"bannerIconLight"}>
              <a
                href="https://github.com/Mian-Uzman"
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub />
              </a>
            </span>
            <span className={darkMode?"bannerIconDark":"bannerIconLight"}>
              <a
                href="https://www.linkedin.com/in/mian-uzman-munib/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </span>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className={darkMode?"bannerIconDark":"bannerIconLight"}>
              <FaReact />
            </span>
            <span className={darkMode?"bannerIconDark":"bannerIconLight"}>
              <SiNextdotjs />
            </span>
            <span className={darkMode?"bannerIconDark":"bannerIconLight"}>
              <SiTailwindcss />
            </span>
            <span className={darkMode?"bannerIconDark":"bannerIconLight"}>
              <SiFigma />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBanner;
