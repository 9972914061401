import React from "react";
import Title from "../layouts/Title";
import { projectOne, projectTwo, projectBlog, projectMusic, projectUber } from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title title="VIEW MY PORTFOLIO" des="My Projects" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Workout Tracker App"
          des="App built to track your workout progression. Plan your next workout and meals!"
          src={projectOne}
          githubLink={"https://github.com/Mian-Uzman/Workout-Tracker-MERN"}
          tags={["react", "express", "node"]}
        />
        <ProjectsCard
          title="Client Business Website"
          des="Fully functional mobile responsive website for a client. Complete services from desing to development and the deployement"
          src={projectTwo}
          githubLink={"https://github.com/Mian-Uzman/oxystech-website"}
          tags={["react", "express", "node"]}
        />
        <ProjectsCard
          title="Blogging App"
          des="Complete blogging app with authentication, user feeds and blogs with comments functionality"
          src={projectBlog}
          githubLink={"https://github.com/Mian-Uzman/blog-app-angular"}
          tags={["angular", "express", "node"]}
        />
        <ProjectsCard
          title="Uber CLONE"
          des="A fully responsive pixel perfect frontent clone of Uber website"
          src={projectUber}
          githubLink={'https://github.com/Mian-Uzman/uber-clone'}
          tags={["html", "css"]}

        />
        <ProjectsCard
          title="Music Sharing App"
          des="App created to listen to music with your friends in dedicated rooms, streaming through spotify API"
          src={projectMusic}
          githubLink={"https://github.com/Mian-Uzman/MusicAPP"}
          tags={["next", "express", "node"]}
        />
      </div>
    </section>
  );
};

export default Projects;
