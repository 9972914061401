import React, { useContext } from "react";
import { ThemeContext } from "../../context";

const ResumeCard = ({ title, subTitle, result = "", des }) => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="w-full h-1/3 group flex">
      <div className="w-10 h-[6px] bgOpacity mt-16 relative">
        <span className="absolute w-5 h-5 rounded-full -top-2 -left-3 flex justify-center items-center bg-black bg-opacity-60">
          <span className="w-3 h-3 rounded-full bg-bodyColorDark inline-flex group-hover:bg-designColor duration-300"></span>
        </span>
      </div>
      <div className={`w-full ${darkMode?'bg-black shadow-shadowOne bg-opacity-20':'bg-gray-200 shadow-lg'}  hover:bg-opacity-30 duration-300  rounded-lg py-16 px-8 lgl:px-10 flex flex-col justify-center gap-6 lgl:gap-10 `}>
        <div className="flex flex-col lgl:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
          <div>
            <h3 className={`text-xl md:text-2xl font-semibold  ${darkMode?'group-hover:text-white':'group-hover:text-gray-500'}  duration-300`}>
              {title}
            </h3>
            <p className={`text-sm mt-2 ${darkMode?'text-gray-400 group-hover:text-white':'text-darkText group-hover:text-gray-500'}  duration-300`}>
              {subTitle}
            </p>
          </div>
          <div>
            {result && (
              <p className={`px-4 py-2 text-designColor ${darkMode?'bg-black bg-opacity-25 shadow-shadowOne':'bg-white shadow-md'} rounded-lg flex justify-center items-center text-sm font-medium`}>
                {result}
              </p>
            )}
          </div>
        </div>
        <p className={`text-sm md:text-base font-medium ${darkMode?'text-gray-400 group-hover:text-gray-300':'text-darkTex group-hover:text-gray-600'}  duration-300`}>
          {des}
        </p>
      </div>
    </div>
  );
};

export default ResumeCard;
