import React from "react";
import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";
import Title from "../layouts/Title";
import Card from "./Card";

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="My skills" des="What can I help you with?" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="Business Stratagy"
          des=" Tailoring strategies that drive growth and align with your business goals to ensure sustained success."
        />
        <Card
          title="Web Development"
          des=" Building robust, scalable, and user-friendly apps that meet your business needs and engage your audience."
          icon={<AiFillAppstore />}
        />
        <Card
          title="SEO Optimisation"
          des="Enhancing your online presence with targeted SEO techniques to boost visibility and drive organic traffic."
          icon={<SiProgress />}
        />
        <Card
          title="Mobile Development"
          des="Creating seamless and responsive mobile solutions that deliver an exceptional user experience across all devices."
          icon={<FaMobile />}
        />
        <Card
          title="UX Design"
          des="Designing intuitive and visually compelling interfaces that provide an optimal user experience and enhance customer satisfaction."
          icon={<SiAntdesign />}
        />
        <Card
          title="Hosting Websites"
          des="Providing reliable and secure hosting solutions to ensure your website runs smoothly and efficiently at all times."
          icon={<FaGlobe />}
        />
      </div>
    </section>
  );
};

export default Features;
