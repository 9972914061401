import React, { useState, useContext } from "react";
import { Link } from "react-scroll";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { navLinksdata } from "../../constants";
import Toggle from "../toggle/toggle";
import { ThemeContext } from "../../context";

const Navbar = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div
      className={`max-w-screen-2xl mx-auto w-full h-24 sticky top-0 z-50 ${
        darkMode ? "bg-bodyColorDark" : "bg-bodyColorLight"
      }  mx-auto flex justify-between items-center font-titleFont border-b-[1px] border-b-gray-600`}
    >
      <div>
        <ul className="hidden mdl:inline-flex items-center gap-6 lg:gap-10">
          {navLinksdata.map(({ _id, title, link }) => (
            <li
              className={`text-base font-normal ${
                darkMode ? "text-lightText" : "text-darkText"
              }  tracking-wide cursor-pointer hover:text-designColor duration-300`}
              key={_id}
            >
              <Link
                activeClass={darkMode ? "text-white" : "text-gray-400"}
                to={link}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide">
            <div className="flex flex-col gap-8 py-2 relative">
              
              <ul className="flex flex-col gap-4 mt-8">
                {navLinksdata.map((item) => (
                  <li
                    key={item._id}
                    className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
                  >
                    <Link
                      onClick={() => setShowMenu(false)}
                      activeClass={darkMode ? "text-white" : "text-gray-400"}
                      to={item.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex flex-col gap-4">
                <h2 className="text-base uppercase font-titleFont mb-4">
                  Find me in
                </h2>
                <div className="flex gap-4">
                  <span className="bannerIconDark">
                    <FaFacebookF />
                  </span>
                  <span className="bannerIconDark">
                    <FaTwitter />
                  </span>
                  <span className="bannerIconDark">
                    <FaLinkedinIn />
                  </span>
                </div>
              </div>
              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
      <div>
        {/* <img src={logo} alt="logo" /> */}
        <Toggle />
      </div>
    </div>
  );
};

export default Navbar;
