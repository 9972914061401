import React, { useContext } from "react";
import { ThemeContext } from "../../context";

const Toggle = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const handleToggle = () => {
    theme.dispatch({ type: "TOGGLE" });
  };

  return (
    <div className="t">
      {/* <img src={Sun} alt="" className="t-icon" /> */}
      {/* <img src={Moon} alt="" className="t-icon" /> */}
      <div className="hover:cursor-pointer" onClick={handleToggle}>
        {darkMode ? (
          <p className="text-lightText">Light Mode</p>
        ) : (
          <p className="text-gray-600">Dark Mode</p>
        )}
      </div>
    </div>
  );
};

export default Toggle;
