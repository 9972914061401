import React, { useState, useContext } from "react";
import Title from "../layouts/Title";
import Education from "./Education";
import Skills from "./Skills";
import { ThemeContext } from "../../context";

const Resume = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const [educationData, setEducationData] = useState(false);
  const [skillData, setSkillData] = useState(true);
  return (
    <section id="resume" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title title="3+ YEARS OF EXPERIENCE" des="My Resume" />
      </div>
      <div>
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          <li
            onClick={() => setEducationData(false) & setSkillData(true)}
            className={`${
              skillData
                ? "border-designColor rounded-lg"
                : "border-transparent rounded-lg"
            } ${darkMode ? "resumeLiDark" : "resumeLiLight"}`}
          >
            Professional Skills
          </li>
          <li
            onClick={() => setEducationData(true) & setSkillData(false)}
            className={`${
              educationData
                ? "border-designColor rounded-lg"
                : "border-transparent rounded-lg"
            } ${darkMode ? "resumeLiDark" : "resumeLiLight"}`}
          >
            Experience
          </li>
        </ul>
      </div>
      {educationData && <Education />}
      {skillData && <Skills />}
    </section>
  );
};

export default Resume;
